@font-face {
  font-family: "Cresta Bold";
  src: url("../fonts/Cresta-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Cresta Medium";
  src: url("../fonts/Cresta-Medium.ttf");
  font-weight: medium;
}

@font-face {
  font-family: "Cresta Regular";
  src: url("../fonts/Cresta-Regular.ttf");
  font-weight: normal;
}

.App {
  text-align: center;
  padding-top: 20px; /* Add some top padding to create distance */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #646464; /* Set font color to #646464 */
}

/* Set link color to dark grey and remove underlines */
.App-link,
.nav-link {
  color: #646464; /* Set font color to #646464 */
  text-decoration: none;
}

/* Change link color on hover */
.App-link:hover,
.nav-link:hover {
  color: grey;
}

.logo {
  width: 120px; /* Adjust the size as needed */
  height: auto;
  align-self: left;
  margin-left: 5px;
}

.dashboard-preview-container {
  text-align: center;
  margin-bottom: 50px; /* Adjust the spacing between thumbnail icons */
}

.dashboard-preview {
  display: inline-block;
  max-width: 500px;
  margin: 0 auto;
}

.dashboard-preview img {
  width: 100%;
  height: auto;
}

/* Add a new class for centering the second-row items */
.dashboard-preview-container.center {
  display: flex;
  justify-content: center;
}

/* Optional: Add some spacing between the items in the second row */
.dashboard-preview-container.center .dashboard-preview {
  margin-left: 10px;
  margin-right: 10px;
}

/* Add spacing to the main content area (between the navbar and the grid) */
main {
  padding-top: 100px; /* Adjust the spacing between the navbar and the grid */
}

.dashboard-preview:hover {
  background-color: lightblue;
}

.dashboard-preview h3 {
  font-family: "Cresta Regular";
  font-size: 16px;
  margin-top: 8px;

  color: #646464; /* Set font color to #646464 */
}

nav a {
  font-family: "Cresta Medium";
  font-size: 20px;
  color: #646464;
  text-decoration: none; /* Remove underline from links in the navbar */
}

.color-rectangle {
  width: 12px; /* Change size of the rectangle as needed */
  height: 24px;
  margin-right: 8px; /* Add distance between rectangle and text */
  margin-left: 10px;
  flex-shrink: 0; /* Prevent shrinking */
  flex-grow: 0; /* Prevent growing */
}
